import { render, staticRenderFns } from "./perfilesDetalle.vue?vue&type=template&id=6c1e3586&"
import script from "./perfilesDetalle.vue?vue&type=script&lang=js&"
export * from "./perfilesDetalle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QBreadcrumbs,QBreadcrumbsEl,QTabs,QTab,QScrollArea,QTabPanels,QTabPanel,QMarkupTable,QTr,QTd,QBadge,QCardSection,QExpansionItem,QTable,QForm,QInput,QBtn});
