<template>
  <q-page>
    <!-- EMPIEZA HEADER GLOBAL -->
    <div class="w100p bg-gris-panel q-px-lg q-py-sm row">
      <div class="col-8">
        <div class="titulo-header">{{perfilObj.clave}} - {{perfilObj.titulo}}</div>
        <q-breadcrumbs gutter="xs" class="mt-minus-2" active-color="grey-system">
          <template v-for="(breadcrumb, i) in breadcrumbs">
            <q-breadcrumbs-el :key="i" :label="breadcrumb.name" :to="breadcrumb.to" :class="{'text-template': (i+1)===breadcrumbs.length}"/>
          </template>
        </q-breadcrumbs>
      </div>
    </div>
    <!-- TERMINA HEADER GLOBAL -->
    <!-- COMIENZA BODY GLOBAL  -->
    <!-- COMIENZA TAB -->
    <div class="items-start row">
      <div class="col-12 q-mb-md q-pl-xl shadow-down-4 border-top">
        <q-tabs v-model="cardTab" align="left" indicator-color="orange-6" dense class="h40">
          <q-tab class="text-grey-system" no-caps content-class="w100p tab-detail" label="Información" name="informacion"/>
        </q-tabs>
      </div>
      <q-scroll-area class="h100-165 col-12">
        <div class="row justify-center">
          <q-tab-panels v-model="cardTab" class="content-container-config" animated swipeable vertical
                        transition-prev="jump-up" transition-next="jump-up">
            <q-tab-panel name="informacion">
              <div class="header-content">
                Datos Generales de Perfil
                <a @click="abrirModalEditar" class="text-font-regular fs-13 q-pl-sm q-pt-xs cursor-pointer"><span
                    style="text-decoration: underline;">(Editar Información)</span></a>
              </div>
              <div class="q-mb-lg row justify-between">
                <q-markup-table class="w49p" dense flat separator="none" wrap-cells>
                  <tbody>
                  <q-tr no-hover>
                    <q-td class="w30p fs-w-600">Nombre</q-td>
                    <q-td class="w70p">{{perfilObj.titulo}}</q-td>
                  </q-tr>
                  <q-tr no-hover>
                    <q-td class="w30p fs-w-600">Clave</q-td>
                    <q-td class="w70p">{{perfilObj.clave}}</q-td>
                  </q-tr>
                  <q-tr no-hover>
                    <q-td class="w30p fs-w-600">Descripción</q-td>
                    <q-td class="w70p">{{perfilObj.descripcion}}</q-td>
                  </q-tr>
                  <q-tr no-hover>
                    <q-td class="w30p fs-w-600">Permisos Asignados</q-td>
                    <q-td class="w70p">{{perfilObj.total_permisos_asignados}}  / {{perfilObj.total_permisos}}</q-td>
                  </q-tr>
                  </tbody>
                </q-markup-table>
                <q-markup-table class="w49p" dense flat separator="none" wrap-cells>
                  <tbody>
                  <q-tr no-hover>
                    <q-td class="w30p fs-w-600">Registro Autor</q-td>
                    <q-td class="w70p">{{perfilObj.registro_autor}}</q-td>
                  </q-tr>
                  <q-tr no-hover>
                    <q-td class="w30p fs-w-600">Registro Fecha</q-td>
                    <q-td class="w70p">{{ dateFormat(perfilObj.registro_fecha, 2, true) }}</q-td>
                  </q-tr>
                  <q-tr no-hover>
                    <q-td class="w30 fs-w-600">Actualización Autor</q-td>
                    <q-td>{{perfilObj.actualizacion_autor}}</q-td>
                  </q-tr>
                  <q-tr no-hover>
                    <q-td class="w30p fs-w-600">Actualización Fecha</q-td>
                    <q-td class="w70p">{{ dateFormat(perfilObj.actualizacion_fecha, 2, true) }}</q-td>
                  </q-tr>
                  <q-tr no-hover>
                    <q-td class="w30p fs-w-600">Status</q-td>
                    <q-td class="w70p">
                      <q-badge :color="colorStatusBadge(perfilObj.status, 200)"
                               :label="perfilObj.status === 200 ? 'Activo' : 'Eliminado'"></q-badge>
                    </q-td>
                  </q-tr>
                  </tbody>
                </q-markup-table>
              </div>
              <div class="header-content">Permisos
                <router-link class="text-font-regular fs-13 q-pl-sm q-pt-xs" :to="{name:'perfilEditarPermisos',params:{id:perfilId}}">
                  (<span style="text-decoration: underline;">Editar Permisos</span>)
                </router-link>
              </div>
              <q-card-section class="table-content">
                <template v-for="(permiso, index) in permisos">
                  <q-expansion-item :key="index" header-class="fs-17 fs-w-600 border-bottom" class="q-mb-md" :label="permiso.seccion">
                    <q-table
                        flat dense border separator="cell"
                        :data="JSON.parse(permiso.permisos)"
                        :columns="columnas"
                        row-key="permiso_id"
                        color="green"
                        hide-bottom
                        hide-header
                        :rows-per-page-options="[0]"
                    />
                  </q-expansion-item>
                </template>
              </q-card-section>
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </q-scroll-area>
    </div>
    <!-- TERMINA TAB -->
    <!-- TERMINA BODY GLOBAL  -->
    <!-- COMIENZA SECCION DE MODALES -->
    <!-- COMIENZA MODAL ALTA -->
    <modal :model="modalEditar"
                  @on-close="cerrarModalEditar"
                  globalClases="w530"
                  titulo="Editar Perfil">
      <template #body>
        <q-form @submit.prevent="editar()" class="row">
          <input hidden ref="inputSubmit" type="submit"/>
          <div class="row q-mb-md col-12">
            <div class="col-3 text-right q-pt-sm fs-w-600">Clave</div>
            <div class="col-min text-red text-center q-pt-xs fs-17">*</div>
            <div class="col-7">
              <q-input
                  dense
                  lazy-rules="ondemand"
                  outlined
                  ref="first"
                  v-model.trim="editarPerfilObj.clave"
                  :rules="[
                      (val) => !!val || 'Campo requerido',
                      (val)=> val.length>=3 || 'No menos de 3 caracteres',
                      (val)=> val.length<=12 || 'No más de 10 caracteres'
                  ]"
              />
            </div>
          </div>
          <div class="row q-mb-md col-12">
            <div class="col-3 text-right q-pt-sm fs-w-600">Nombre</div>
            <div class="col-min text-red text-center q-pt-xs fs-17">*</div>
            <div class="col-7">
              <q-input
                  dense
                  lazy-rules="ondemand"
                  outlined
                  v-model.trim="editarPerfilObj.titulo"
                  :rules="[
                      (val) => !!val || 'Campo requerido',
                      (val)=> val.length>=5 || 'No menos de 5 caracteres',
                      (val)=> val.length<=50 || 'No más de 50 caracteres'
                  ]"
              />
            </div>
          </div>
          <div class="row q-mb-md col-12">
            <div class="col-3 text-right q-pt-sm fs-w-600">Descripción</div>
            <div class="col-min text-red text-center q-pt-xs fs-17">*</div>
            <div class="col-7">
              <q-input
                  maxlength="250"
                  lazy-rules="ondemand"
                  outlined
                  type="textarea"
                  input-class="h70"
                  v-model.trim="editarPerfilObj.descripcion"
                  :rules="[(val) => val.length < 90 || 'No más de 90 caracteres']"
              />
            </div>
          </div>
        </q-form>
      </template>
      <template #footer>
        <q-btn
            @click="cerrarModalEditar()"
            class="q-mr-sm"
            color="primary"
            flat
            label="Cerrar ventana"
            no-caps
            outline
            unelevated
        />
        <q-btn @click="$refs.inputSubmit.click()" color="primary" unelevated label="Guardar"/>
      </template>
    </modal>
    <!-- FINALIZA MODAL ALTA -->
  </q-page>
</template>

<script>

  import PerfilService from "../../Services/PerfilService";

  const perfilService = new PerfilService();
  export default {
    name: "perfilDetalle",
    data() {
      return {
        breadcrumbs: [{name: "Home", to: "/home"}, {name: "Perfiles", to: "/configuraciones/perfiles"}, {name: "Detalle de Perfil"}],
        cardTab: "informacion",
        columnas: [{name: 'permiso', label: '', field: 'titulo', align: 'left'}],
        permisos: [],
        perfilId: this.$route.params.id,
        perfilObj: {},
        editarPerfilObj: {},
        modalEditar: false,
        validacionClave: "",
      }
    },
    mounted() {
      this.obtenerDetalle();
    },
    methods: {
      obtenerDetalle(loader = true) {
        if (loader) this.loader(true);
        let filtros = {perfilId: this.perfilId};
        perfilService.obtenerPermisoPerfil(filtros)
          .then(datos => {
            this.perfilObj = datos.info;
            this.permisos  = datos.permisos;
            this.validacionClave = this.perfilObj.clave;
          })
          .catch(error => this.alertShow(error, 'error'))
          .then(() => {if (loader) this.loader(false);});
      },
      abrirModalEditar() {
        this.editarPerfilObj = _.clone(this.perfilObj);
        this.$nextTick(() => this.$refs.first.focus());
        this.modalEditar = true;
      },
      cerrarModalEditar() {
        this.editarPerfilObj = {};
        this.modalEditar     = false;
      },
      editar() {
        this.loader(true);
        let validacionBol = this.validarClave();
        let data     = {
            perfilId: this.editarPerfilObj.perfil_id, 
            clave: this.editarPerfilObj.clave,
            nombre: this.editarPerfilObj.titulo, 
            descripcion: this.editarPerfilObj.descripcion, 
            validacion: validacionBol
        }
        perfilService.editar(data)
          .then(respuesta => {
            this.alertShow(respuesta.mensaje, "exito");
            this.cerrarModalEditar();
            this.obtenerDetalle();
            this.loader(false);
          }).catch(error => {
          this.alertShow(error, "error")
          this.loader(false);
        });
      },
      validarClave() {
        if (this.validacionClave == this.editarPerfilObj.clave)
          return 0;
        else
          return 1;
      },
    },

  }
</script>